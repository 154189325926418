<template>
  <KTCard
    :page="page"
    :isForm="true"
    :isEdit="true"
    @submit="onSubmit"
    :isLoading="ApiIsLoading"
    :cancelTo="parentRoute"
    :readonly="finalizado"
  >
    <template #body>
      <b-form-group label-size="lg" label-cols-md="4" label-cols-lg="3">
        <template #label>
          Valor do Investimento Anterior Realizado em Capital de Giro
          <span class="text-danger">*</span>
        </template>
        <b-input-group :prepend="simbolo_moeda">
          <KTInputNumeric
            v-model="form.investimento_realizado"
            :precision="2"
            autocomplete="off"
            :required="!finalizado"
            :disabled="finalizado"
          />
        </b-input-group>
      </b-form-group>

      <b-row>
        <b-col
          md="6"
          lg="4"
          xl="3"
          v-for="(ano, index) in anos"
          :key="'ano-' + ano"
        >
          <b-card no-body class="p-3 mt-8">
            <p class="font-weight-bold">Ano {{ ano }}</p>
            <b-form-group>
              <template #label>
                Prazo Médio dos Estoques <span class="text-danger">*</span>
              </template>
              <template #default>
                <KTInputNumeric
                  v-model="form.capital_giro[index].prazo_medio_estoques"
                  :precision="0"
                  autocomplete="off"
                  :required="!finalizado"
                  :disabled="finalizado"
                />
              </template>
            </b-form-group>
            <b-form-group>
              <template #label>
                Prazo Médio dos Recebimentos <span class="text-danger">*</span>
              </template>
              <template #default>
                <KTInputNumeric
                  v-model="form.capital_giro[index].prazo_medio_recebimentos"
                  :precision="0"
                  autocomplete="off"
                  :required="!finalizado"
                  :disabled="finalizado"
                />
              </template>
            </b-form-group>
            <b-form-group>
              <template #label>
                Prazo Médio de Pagamentos <span class="text-danger">*</span>
              </template>
              <template #default>
                <KTInputNumeric
                  v-model="form.capital_giro[index].prazo_medio_pagamentos"
                  :precision="0"
                  autocomplete="off"
                  :required="!finalizado"
                  :disabled="finalizado"
                />
              </template>
            </b-form-group>
            <b-form-group class="mb-0">
              <template #label>
                Percentual de Vendas à Prazo <span class="text-danger">*</span>
              </template>
              <template #default>
                <b-input-group append="%">
                  <KTInputNumeric
                    v-model="form.capital_giro[index].percentual_vendas_a_prazo"
                    :precision="2"
                    :min="0"
                    :max="100"
                    autocomplete="off"
                    :required="!finalizado"
                    :disabled="finalizado"
                  />
                </b-input-group>
              </template>
            </b-form-group>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </KTCard>
</template>

<script>
import ApiMixin from "@/core/mixins/api.mixin.js";
import HelpersMixin from "@/core/mixins/helpers.mixin.js";

export default {
  name: "PipCapitalGiroForm",
  props: {
    idplano_investimento: {
      required: true
    },
    idpip_capitao_giro: {
      default: null
    }
  },
  mixins: [ApiMixin, HelpersMixin],
  data() {
    return {
      page: {
        icon: "la la-cash-register",
        title: "Necessidade de Capital de Giro",
        description: `A necessidade de capital de giro (NCG) é o montante mínimo que
          uma empresa deve ter em caixa. Esse valor serve para manter a empresa
          funcionando, assegurando as operações necessárias. Insira, em dias, os prazos
          médios dos estoques, dos recebimentos e dos pagamentos de acordo com as
          características do seu negócio.`
      },
      form: {
        investimento_realizado: null,
        capital_giro: []
      },
      anos: 0,
      simbolo_moeda: null,
      tipo_plano_investimento: null,
      finalizado: true
    };
  },
  computed: {
    apiResource() {
      return `pip/${this.idplano_investimento}/capital_giro`;
    },
    manual() {
      return {
        session: "capital_giro",
        tipo: this.tipo_plano_investimento
      };
    },
    parentRoute() {
      return {
        name: "pip_capital_giro_list",
        params: { idplano_investimento: this.idplano_investimento }
      };
    }
  },
  methods: {
    getPageData() {
      const requests = [this.Api.GetData()];

      const resolveFuntion = (res) => {
        // Requests index's
        const R_GETDATA = 0;

        this.anos = res[R_GETDATA].anos;
        this.simbolo_moeda = res[R_GETDATA].simbolo_moeda;

        this.form.investimento_realizado =
          res[R_GETDATA].investimento_realizado ?? null;

        if (res[R_GETDATA].capital_giro && res[R_GETDATA].capital_giro.length) {
          this.form.capital_giro = res[R_GETDATA].capital_giro;
        } else {
          this.form.capital_giro = [];
          for (let i = 1; i <= this.anos; i++) {
            this.form.capital_giro.push({
              idpip_capitao_giro: this.idpip_capitao_giro,
              ano: i
            });
          }
        }
        this.tipo_plano_investimento = res[R_GETDATA].tipo_plano_investimento;
        this.finalizado = res[R_GETDATA].finalizado;

        this.initManual(this.manual.session, this.manual.tipo);
      };

      this.ApiGetPageData(requests, resolveFuntion);
    },
    onSubmit() {
      this.ApiSubmitAdd(this.form);
    }
  },
  mounted() {
    this.getPageData();
  }
};
</script>
